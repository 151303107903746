import { http } from '@/http/axios.js'

// 列表
export function getList(params) {
  return http({
    url: `/company/basic/feedback/list`,
    method: 'get',
    params
  })
}

export function removeFeedback(params) {
  return http({
    url: `/company/basic/feedback/del`,
    method: 'post',
    params: params
  })
}

// export function addTeacher(params) {
//   return http({
//     url: `/company/teacher/add`,
//     method: 'post',
//     params: params
//   })
// }

// export function editTeacher(params) {
//   return http({
//     url: `/company/teacher/edit`,
//     method: 'post',
//     params: params
//   })
// }

export function detail(id) {
  return http({
    url: `/company/basic/feedback/detail`,
    method: 'get',
    params: { id: id }
  })
}
