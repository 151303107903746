<template>
  <!-- 老师管理 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="反馈标题">
          <el-input style="width:250px" v-model="searchData.name" title="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-button style="margin-bottom:20px;" type="primary" icon="el-icon-plus" size="small" @click="goAdd"> 添加</el-button> -->
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="page_size"
    />
  </div>
</template>

<script>
import { getList, removeFeedback } from './api'

import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  // {
  //   label: '反馈标题',
  //   prop: 'title',
  //   minWidth: '120',
  //   customRender(h, row) {
  //     return <p>{row['title']}</p>
  //   }
  // },
  // {
  //   label: '反馈人昵称',
  //   minWidth: '100',
  //   prop: 'nickname',
  //   customRender(h, row) {
  //     return <p>{ row['user'] === null ? "-" : row['user']['nickname']}</p>
  //   }
  // },
  // {
  //   label: '反馈人手机号',
  //   minWidth: '150',
  //   prop: 'mobile',
  //   customRender(h, row) {
  //     return <p>{ row['user'] === null ? "-" : row['user']['mobile']}</p>
  //   }
  // },
  {
    label: '反馈内容',
    minWidth: '600',
    prop: 'content',
    customRender(h, row) {
      return <p class="one-line">{row['content']}</p>
    }
  },
  {
    label: '反馈时间',
    minWidth: '160',
    prop: 'created_at',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['created_at']}>
          {row['created_at']}
        </p>
      )
    }
  },
  {
    label: '操作',
    fixed: 'right',
    minWidth: '200',
    customRender(h, row) {
      return (
        <div>
          {/* <el-link type="primary" style="margin-right:10px;" onClick={() => this.goEdit(row['id'])}>
            编辑
          </el-link> */}
          <el-link type="danger" onClick={() => this.goRemove(row['id'])}>
            删除
          </el-link>
        </div>
      )
    }
  }
]

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      dialogVisible: false,
      isShow: false,
      list: [],
      page_size: 10,
      searchData: {
        name: '',
        mobile: ''
        // roleId: ''
      },
      total: 0,
      currentPage: 1,
      columns,
      roleList: []
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },

  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        title: this.searchData.title
      }
      const res = await getList(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    async goRemove(id) {
      this.$confirm('此操作将永久删除该老师, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }) .then(() => {
          removeFeedback({ id }).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    changeStatus(row) {
      let { id, status } = row
      let data = { id, status: status == 20 ? 20 : 10 }
      let statusDesc = status == 10 ? '禁用' : '启用'

      this.$confirm(`此操作将该老师${statusDesc}吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          row.status = status == 20 ? 20 : 10
          changeStatus(data).then(() => {
            this.getGoodsCategoryTree()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          row.status = status == 10 ? 20 : 10
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    updatePwd(id) {
      this.dialogVisible = true
    },
    savePwd() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
